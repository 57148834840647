@import '../../../../scss/theme-bootstrap';

.basic-divider {
  @include breakpoint($medium-up) {
    height: 1px;
    width: 90%;
    max-width: $max-width-large;
    margin: 20px auto;
    background: $color-lighter-gray;
  }
}
